import { render, staticRenderFns } from "./TextImage.vue?vue&type=template&id=17fba3a6"
import script from "./TextImage.vue?vue&type=script&lang=js"
export * from "./TextImage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesTextImage: require('/src/components/molecules/TextImage.vue').default,AtomsContainer: require('/src/components/atoms/Container.vue').default,AtomsModuleWrapper: require('/src/components/atoms/ModuleWrapper.vue').default})
