
import { arrayOf, nullable, oneOfType, shape, string } from 'vue-types';
import { customLinkFieldShape, imageShape } from '~/constants/PropTypes';
export default {
  inheritAttrs: false,
  props: {
    children: arrayOf({
      id: string(),
      heading: oneOfType([string(), nullable()]),
      body: oneOfType([string(), nullable()]),
      link: arrayOf(shape(customLinkFieldShape).loose),
      visual: arrayOf(shape(imageShape).loose),
    }),
  },
};
